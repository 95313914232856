import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import styled, { keyframes } from "styled-components";
import {
  colors,
  fontStyles,
  zIndex,
  transitions,
  navHeight,
  cursors,
  grid,
} from "../styles/variables";
import { useAnimationStore } from "../stores/AnimationStore";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Video } from "gatsby-video";
import ProjectNav from "../components/ProjectNav";
import { GridImage } from "../components/GridImage";
import { mediaQueries, screenSizes } from "../styles/queries";

const TitleAnimation = keyframes`
  0% {
    transform: skewY(0) translateY(46px);
  }
  100% {
    transform: skewY(10deg) translateY(46px);
  }
;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  min-height: var(--100dvh);
  ${grid.outer};
  max-width: ${screenSizes.maxWideScreen}px;

  cursor: inherit;
`;
const Inner = styled.div`
  width: 100%;
  height: 100%;
  min-height: var(--100dvh);
  ${grid.inner};
  grid-template-rows: 0rem ${navHeight} repeat(auto-fit, minmax(min-content, 0));
  ${mediaQueries.medium} {
    grid-template-columns: [col-start] 0rem repeat(3, 1fr) 0rem [col-end];
  }
  cursor: inherit;
`;

const EndlessSection = styled.section`
  display: grid;
  grid-template-columns: [col-start] 1fr 1fr 1fr 1fr 1fr [col-end];
  /* grid-template-rows: repeat(auto-fill, 1fr); */
  grid-auto-rows: minmax(30px, auto);
  grid-area: 3 / 2 / 7 / 7;
  grid-gap: 2rem 2rem;
  ${mediaQueries.medium} {
    grid-template-columns: [col-start] 1fr 1fr 1fr [col-end];
    grid-area: 3 / 2 / 7 /5;

    /* grid-auto-rows: 25vh; */
    /* grid-template-rows: [col-start] 1fr 1fr 1fr [col-end]; */
  }
  width: 100%;
  min-height: var(--100dvh);
  margin: 0 auto;
  cursor: inherit;
`;

const H1 = styled.h1`
  color: ${(props) => (props.color ? props.color : colors.black)};
  -webkit-text-stroke: ${(props) =>
    (props.fontColor !== colors.black ? "0.9px" : "1px") +
    (props.fontColor ? props.fontColor : "transparent")};
  ${fontStyles.h1};
  font-size: 12rem;
  /* text-transform: uppercase; */
  hyphens: auto;
  z-index: ${zIndex.low};
  margin-block: 0px;
  /* transform: translateY(50px) translateX(35px); */
  transition: ${transitions.t1};
  cursor: inherit;
  /* animation: ${TitleAnimation} 0.5s ease-in-out forwards; */
  grid-column: col-start / col-end;
  margin-top: -15px;
  /* padding-left: 48px; */
  /* padding-top: ${navHeight}; */
  ${mediaQueries.medium} {
    transform: none;
    font-size: 8rem;
    -webkit-text-stroke: 1px
      ${(props) => (props.color ? props.fontColor : "transparent")};
  }
  ${mediaQueries.small} {
    font-size: 5rem;
  }
`;

const Text = styled.div`
  ${(props) => !props.visible && `opacity: 0;`}
  display: grid;
  grid-column: 1;
  ${(props) => props.gridColumn && `grid-column: ${props.gridColumn};`}
  ${mediaQueries.small} {
    grid-area: ${(props) => props.gridAreaMobile && props.gridAreaMobile};
  }
  z-index: ${zIndex.medium};
  font-weight: 500;
  cursor: inherit;
  p {
    /* margin-block-start: 0; */
    /* margin: 0; */
  }
`;
const List = styled.ul`
  ${(props) => !props.visible && `opacity: 0;`}
  display: flex;
  flex-direction: column;
  grid-column: 1;
  ${(props) => props.gridColumn && `grid-column: ${props.gridColumn};`}
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  align-self: flex-start;
  z-index: ${zIndex.medium};
  cursor: inherit;
  li {
    color: ${(props) => (props.color ? props.color : colors.black)};
  }
`;
const ListItem = styled.li`
  list-style: none;
  cursor: inherit;
`;
const ListItemLink = styled.a`
  list-style: none;
  color: inherit;
  cursor: inherit;
`;

const BigParagraph = styled.p`
  /* border-top: 2px solid ${colors.black}; */
  color: ${(props) => (props.color ? props.color : colors.black)};
  margin-block-end: 0;
  padding-top: 0rem;
  /* ${fontStyles.h3}; */
  font-size: 1.3rem;
  font-weight: 400;
  width: 100%;
  margin-top: 0;
  line-height: 135%;

  cursor: inherit;
  ${(props) => props.bold && `font-weight: 600;`}
  * {
    cursor: inherit;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const MiniTitle = styled.h6`
  color: inherit;
  ${fontStyles.capitals};
  margin-block-start: 0;
  margin-block-end: 0.2rem;
`;

const InfoWrapper = styled.div`
  ${(props) => props.fontColor && `color: ${props.fontColor};`}
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-column: 1;
  ${mediaQueries.medium} {
    grid-column: col-start/col-end;
  } ;
`;
const HorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const ProjectTemplate = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { backgroundColor, setBackgroundColor, setFontColor, fontColor } =
    useAnimationStore();

  React.useEffect(() => {
    if (backgroundColor !== frontmatter.color) {
      setBackgroundColor(frontmatter.color);
      setFontColor(frontmatter.fontColor);
    }
  }, [backgroundColor]);

  console.log("frontmatter", frontmatter);
  // const video1 = frontmatter?.featuredImages[0]?.videoSrc?.childVideoFfmpeg;

  // const video2 = frontmatter?.featuredImages[1]?.videoSrc?.childVideoFfmpeg;
  // const video3 = frontmatter?.featuredImages[2]?.videoSrc?.childVideoFfmpeg;

  return (
    <Layout flex fontColor={fontColor}>
      <Wrapper>
        <Inner>
          <Navbar
            // title={frontmatter?.title}
            services={[frontmatter?.title, ...frontmatter?.services]}
            visible={true}
            fixed
          />
          <EndlessSection>
            {/* <h1>{frontmatter.title}</h1> */}
            {/* <h2>{frontmatter.date}</h2> */}
            <H1 color={frontmatter?.color} fontColor={frontmatter?.fontColor}>
              {"" + frontmatter?.title}
            </H1>

            <InfoWrapper fontColor={fontColor}>
              <Text
                gridArea={"3/ 4 / 5 / 3"}
                gridAreaMobile={"3/ 2 / 7 / 7"}
                style={{ marginTop: "2rem" }}
                visible={true}
              >
                <BigParagraph
                  topLine
                  bold
                  dangerouslySetInnerHTML={{ __html: html }}
                  color={frontmatter?.fontColor}
                ></BigParagraph>
              </Text>
              <Text style={{ paddingBottom: "2rem" }} visible={true}>
                <BigParagraph
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.additonalText,
                  }}
                  color={frontmatter?.fontColor}
                ></BigParagraph>
              </Text>
              <HorizontalFlex>
                <List visible={true} color={frontmatter?.fontColor}>
                  <MiniTitle>Services</MiniTitle>
                  {frontmatter?.collaborators &&
                    frontmatter?.services?.map((service) => (
                      <ListItem key={service}>{service}</ListItem>
                    ))}
                </List>
                <List visible={true} color={frontmatter?.fontColor}>
                  <MiniTitle>Collaborators</MiniTitle>
                  {frontmatter?.collaborators &&
                    frontmatter?.collaborators?.map((collaborator) => (
                      <ListItem key={collaborator}>{collaborator}</ListItem>
                    ))}
                </List>
                <List visible={true} color={frontmatter?.fontColor}>
                  <MiniTitle>Ressources</MiniTitle>
                  {frontmatter?.ressources &&
                    frontmatter?.ressources?.map((ressource) => (
                      <ListItemLink
                        key={ressource.name}
                        href={ressource.link}
                        target="blank"
                      >
                        {ressource.name}
                      </ListItemLink>
                    ))}
                </List>
              </HorizontalFlex>
            </InfoWrapper>
            {/* <GridImage
              gridArea={`4/5/6/7`}
              gridAreaMobile={"4/ 2 / 6/ 7"}
              paddingTop={navHeight}
              borderRadius="2rem 2rem 2rem 2rem"
              image={frontmatter?.featuredImages[0]?.src}
              video={video1?.mp4 && [video1?.webm, video1?.mp4]}
            ></GridImage>
            <GridImage
              gridArea={`5/2/10/4`}
              gridAreaMobile={"9/ 2 / 10/ 7"}
              borderRadius="2rem"
              image={frontmatter?.featuredImages[1]?.src}
              video={video2?.mp4 && [video2?.webm, video2?.mp4]}
            ></GridImage>
            <GridImage
              borderRadius="2rem"
              image={frontmatter?.featuredImages[2]?.src}
              video={video3?.mp4 && [video3?.webm, video3?.mp4]}
              fullScreen={frontmatter?.featuredImages[2]?.fullScreen}
              // objectFit="contain"
            ></GridImage> */}
            {frontmatter?.featuredImages.map((image, index) => (
              <GridImage
                borderRadius="2rem"
                image={image.src}
                key={index}
                video={
                  image.videoSrc && [
                    image.videoSrc?.childVideoFfmpeg?.webm,
                    image.videoSrc?.childVideoFfmpeg?.mp4,
                  ]
                }
                project
                fullscreen={image.fullscreen}
                horizontal={image.horizontal}
                vertical={image.vertical}
                square={image.square}
                gridColumnMedium={"col-start / col-end"}
              ></GridImage>
            ))}
          </EndlessSection>
          <ProjectNav
            paddingTop={`3rem`}
            gridArea={`8/2/9/7`}
            static
          ></ProjectNav>
        </Inner>
      </Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        # date(formatString: "MMMM DD, YYYY")
        slug
        title
        services
        color
        fontColor
        layout
        collaborators
        ressources {
          name
          link
        }
        additonalText
        featuredImages {
          alt
          fullscreen
          vertical
          square
          horizontal
          src {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          videoSrc {
            childVideoFfmpeg {
              webm: transcode(
                outputOptions: ["-crf 20", "-b:v 0"]
                maxWidth: 1280
                maxHeight: 720
                fileExtension: "webm"
                codec: "libvpx-vp9"
              ) {
                width
                src
                presentationMaxWidth
                presentationMaxHeight
                originalName
                height
                fileExtension
                aspectRatio
              }
              mp4: transcode(
                maxWidth: 1280
                maxHeight: 720
                fileExtension: "mp4"
                codec: "libx264"
              ) {
                width
                src
                presentationMaxWidth
                presentationMaxHeight
                originalName
                height
                fileExtension
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`;
export default ProjectTemplate;
